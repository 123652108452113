<template>
  <div class="left_resourcetest" >
    <!-- 课程 -->
    <div class="left_resource">
      <div class="title1 indexFz" :class="{ activeTitle: type == 'resource' }" @click="change('resource')">精选课程</div>
      <div class="title2 indexFz"   :class="{ activeTitle: type == 'zixun' }" @click="change('zixun')">名师咨询</div>
      <div class="more" @click="go">更多 ></div>
    </div>
    <!-- 课程list数据 -->
    <div style="margin-top: 15px;" v-if="type == 'resource'">

      <div class="resourceItem" v-for="(item, index) in resourcelist" :key="index"
        :style="{ marginRight: item.isthree ? '0px' : '78px' }" @click="goresourceDetail(item)">
        <img class="pic" :src="item.courseImageUrl"></img>
        <div class="onebox" style="height: 20px;">
          <span class="priceone">{{ item.price }}</span>
          <span class="pricetwo"> 原价{{ item.originalPrice || '--' }} </span>
          <span class="favorable">{{ item.courseGreat }}好评</span>
        </div>
        <div class="twobox">
          <img class="time" src="../assets/imgs/time.png"></img>
          <span class="timeLine">{{ item.startTime }} </span>
          <span class="buypeople"> {{ item.courseEnrollment }}已购</span>
        </div>
      </div>
    </div>
    <!-- 咨询list数据 -->
    <div style="margin-top: 31px;" v-if="type == 'zixun'">
      <div class="resourceItem" v-for="(item, index) in consultList" :key="index" @click="goresourceDetail(item)"
        :style="{ marginRight: item.isthree ? '0px' : '78px' }">
        <img class="pic" :src="item.consultsImageUrl"></img>
        <div class="onebox" style="height: 20px;">
          <span class="priceone">{{ item.price }}</span>
          <span class="buypeople"> {{ item.shopNum }}已购</span>
          <!-- <span class="pricetwo"> 原价{{ item.originalPrice || '--' }} </span> -->
          <!-- <span class="favorable">{{ item.courseGreat }}好评</span> -->
        </div>
        <div class="twobox" style="display: block;">
          <!-- <img class="time" src="../assets/imgs/time.png"></img>
          <span class="timeLine">{{ item.startTime }} </span> -->
          <!-- 标题 -->
          <span class="inlineCss" style="font-size: 16px;display: block;">{{ item.consultsTitle }}</span>
          <!-- 副标题 -->
          <span class="inlineCss" style="font-size: 12px;display: block;">{{ item.consultsTitles }}</span>
        </div>
      </div>
    </div>
    <!-- 测一测 -->
    <div  >
      <ceYice></ceYice>
    </div>
  </div>
</template>

<script>
import { getCourseList, getConsultList } from "@/api/resourceAndconsult.js"
import {
  gkzyCommonParameters
} from "@/api/commonparams"
import ceYice from './ceyice.vue'
export default {
  components: {
    ceYice
  },
  data() {
    return {
      type: 'resource',
      resourcelist: [],
      consultList: [],
      ceyicelist: [

      ],
      ceyiceToastNum:null,
    }
  },
  methods: {
    change(type) {
      this.type = type;
      if (type == 'resource') {    //课程接口

      } else if (type == 'zixun') { //咨询接口

      }
    },
    // 更多点击
    go() {
      if (this.type == 'resource') {
        this.$router.push({ path: "/course" })
      } else if (this.type == 'zixun') {
        this.$router.push({ path: '/service' })
      }
    },
    // 跳转课程+咨询详情
    goresourceDetail(item) {
      if (this.type == 'resource') {
         gkzyCommonParameters({
            parameterType: '课程进入'
          }).then(res => {
              let courseJoin = res.rows[0].parameterValue
              if(courseJoin == 2){  //禁止进入
                     return this.$message.warning('内容正在上传中，敬请期待')
              }
              this.$router.push({ path: "/courseitem", query: { courseId: item.courseId, type: item.type } })

            })


      } else if (this.type == 'zixun') {
        this.$router.push({
          path: '/serviceItem',
          query: {
            consultsId: item.consultsId
          }
        })
      }
    },
    // 获取课程list
    getResourceList() {
      const arr = [2, 5, 8, 11, 14]
      getCourseList({ type: '' }).then(res => {
        let receiveList = res.data.length > 6 ? res.data.splice(0, 6) : res.data
        this.resourcelist = receiveList.map((item, index) => {
          return {
            ...item,
            courseImageUrl: process.env.VUE_APP_BASE_API + item.courseImageUrl,
            startTime: item.startTime.substr(0, 10),
            isthree: arr.includes(index) ? true : false
          }
        })
      })
    },
    // 获取咨询list
    getConsultList() {
      const arr = [2, 5, 8, 11, 14]
      getConsultList().then(res => {
        let receiveList = res.rows.length > 6 ? res.rows.splice(0, 6) : res.rows
        this.consultList = receiveList.map((item, index) => {
          return { ...item, consultsImageUrl: process.env.VUE_APP_BASE_API + item.consultsImageUrl, isthree: arr.includes(index) ? true : false }
        })
      })
    }
  },
  mounted() {
    this.getResourceList()
    this.getConsultList()

  }
}
</script>

<style scoped lang="scss">
.left_resourcetest {
  // flex: 1;
  width: 827px;
  margin-right: 40px;

  .left_resource{
        display: flex;
    height: 40px;
  }


  .title1,
  .title2 {
    // width: 110px;
    box-sizing: border-box;
    padding-left: 13px;
    font-size: 20px;
    line-height: 40px;
    height: 40px;
    // color: #BDBDBD;
  }

  .title2 {
    margin-left: 11px;
  }
    .activeTitle {
    background: url("../assets/imgs/resourcebg.png") no-repeat;
    font-size: 24px!important;
    background-size: contain;
    color: black !important;
    // padding-left: 30px;
  }
  .more {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: auto;
    line-height: 50px;
  }
}
</style>
