<template>
  <div class="index">

    <cardVip ref="cardVip" type="ZZ"></cardVip>
    <!-- 滑动组件 -->
    <!-- <Slider
    :min="0"
    :max="750"
    :initialValue="[406, 496]"
    :width="800"
    :disabled="false"
    :range="true"
    v-model="value"
    @change="onChange"
/> -->

    <div class="lub" style="height: 380px;position: relative;">
      <!-- 轮播图 -->
      <el-carousel height="360px" style="width: 100%;" arrow="always" class="lub">
        <el-carousel-item v-for="(item, index) in introductionList" :key="index">
          <div @click="handLub(index)" class="bannerItemDiv" style="height: 100%;" :style="{ 'background': `url(${item.navCoverUrl}` }"></div>
          <!-- <img height="100%" width="100%" :src="item.navCoverUrl"></img> -->
        </el-carousel-item>

      </el-carousel>

      <div class="fens">
        <div class="textfens">
          <input type="text" :disabled="inputDis" placeholder="请输入分数" v-model="fensInput">
          <img src="../assets/imgs/pen.png" alt="" class="pen">
        </div>

        <div class="chk">
          <span class="indexFz">首选</span>

          <label class="indexFz" v-for="(fruit, index) in fruits" :key="index">
            <input :disabled="subjectDis" type="radio" v-model="selectedFruits" :value="fruit">
            {{ fruit }}
          </label>

        </div>
        <div class="chk">
          <span class="indexFz">再选</span>
          <label class="indexFz" v-for="(fruit, index) in fruits2" :key="index">
            <input type="checkbox" :disabled="optionSubjectDis" v-model="selectedFruits2" :value="fruit">
            {{ fruit }}
          </label>
        </div>
        <div class="chk">
          <span class="indexFz">批次</span>
          <!-- :disabled="index == 1"   不让选择专科批 -->
          <label class="indexFz" v-for="(fruit, index) in fruits3" :key="index">
            <input type="radio" @change="batchChange" v-model="selectedFruits3" :value="fruit">
            {{ fruit }}
          </label>
        </div>
        <div class="nochangeText">
          <!-- 填报期间展示这个提示 -->
          <p v-if="isFillTimeFlag">高考报考期间，不可修改成绩</p>
          <!-- 非填报期间 -->
          <p v-else>

            <!-- 只有普通会员提示信息 -->
            <span v-if="userType == '01'">

              <label v-if="trans == 0">
                今日修改次数已用完
              </label>
              <label v-else>
                今日还可更改{{ trans }}次，成绩决定推荐结果，请认真填写
              </label>
            </span>
          </p>
        </div>
        <div class="nochangeText">
          务必填写真实情况，填报志愿期间一旦提交不可修改
        </div>
        <!-- <div style="font-size: 12px;color: #999999;">目前处于本科批填报志愿阶段，专科批填报暂不开放</div> -->
        <div class="btns">
          <div class="btn" @click="tb(1)">自主填报</div>
          <div class="btn btn2" @click="tb(2)">智能填报</div>
        </div>

      </div>

    </div>

    <div class="minw">
      <!-- 路由盒子 -->
      <div class="routerview">
        <div class="routerviewItem" v-for="(item, index) in list" @click="godetail(item)">
          <img :src="item.img" alt="">
          <span class="indexFz">{{ item.title }}</span>
        </div>
      </div>
      <!-- 课程+咨询+测一测 -->
      <div class="resourceAndInformation" style="margin-top: 50px">
        <!-- 课程+咨询+测一测【子组件】  -->
        <resourceAndExamination></resourceAndExamination>
        <!-- 热门榜单【学校】 -->
        <hotSchool type="1" />
      </div>
      <!-- 资讯【 】 -->
      <div class="resourceAndInformation" style="height: auto;">
        <!-- 左 -->
        <div class="left_resourcetest">
          <div class="left_information" style="height: 40px;">
            <div class="ceyicetitle"><span style="position: relative;z-index: 9;">资讯</span> </div>
          </div>
          <div class="tabArrtwo">
            <img src="../assets/imgs/nodata.png" v-if="ziList.length == 0" style="display: block; margin: 0 auto;width:30%" alt="" />
            <!-- 资讯列表 -->
            <div class="zx" v-for="(item, index) in ziList" :key="index" @click="zxClick(item)">
              <img :src="item.imageUrl" alt="">
              <div class="right">
                <span class="indexFzTwo">{{ item.title }}</span>
                <span>阅读:{{ item.readNum }}</span>
                <span v-if="item.type == 1">置顶</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 右 -->
        <div class="right_school">
          <div class="schooltitle" style=" margin-top: 20px;">
            <span style="font-size: 22px">热门榜单</span>
          </div>
          <div class="schoollist hotright">
            <div class="schoolItem indexFzTwo" v-for="(item, index) in hotDivList" :key="index">
              <img :src="item.iconPath" alt="">
              <span class="schoolhost" @click="ahandclick(item)">{{ item.text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dilogTitle" :visible.sync="dialogVisible" width="80%" :before-close="handleClose" Show-overflow-tooltip="true">

      <el-table :data="tableData" stripe style="width: 100%;" :header-cell-style="{
        backgroundColor: '#f4f4f4',
        color: 'black',
        fontSize: '17px',
        textAlign: 'center',
      }" :cell-style="{
        textAlign: 'center',
      }" @row-click="handleRowClick">
        <el-table-column v-for="(item, index) in tableDataList" :prop="item.prop" :label="item.label" :key=index height="30" show-overflow-tooltip />
      </el-table>
    </el-dialog>
    <!-- <el-dialog title="" custom-class="freeDialog" :visible.sync="isFreeReceive" :close-on-click-modal="false"
      :show-close="false" width="620px">
       show-close
      <div class="freeBox " v-if="isFreeReceive_">
        <i class="el-icon-circle-close closeIcon" @click="isFreeReceive = false"></i>
        <img class="freePic" src="@/assets/imgs/freePic.png" alt="">

        <img class="freeBtn" @click="receiveed" src="@/assets/imgs/freeBtn.png" alt="">
      </div>
      <div v-if="!isFreeReceive_">
        <div class="tc2" v-if="isbuydown" style="width:550px">
          <div class="tcGuanbi">
            <img class="closeImg" src="@/assets/imgs/shanchuguanbicha(2)@2x.png" alt="" @click="isFreeReceive = false">
          </div>

          <img src="@/assets/imgs/底部@2x_.png" alt="">
          <div class="botBox">
            <div class="tc2Text">
              尊敬的用户您好,距离领取资格仅差一步了!
            </div>
            <div class="tc2Text2">
              注意：高考年一旦选择不可修改，请谨慎选择
            </div>
            <div class="tc2Text3">
              <el-select v-model="expiryTime" placeholder="请选择高考年份">
                <el-option v-for="index in 4" :key="index" :label="new Date().getFullYear() + index - 1"
                  :value="new Date().getFullYear() + index - 1">
                </el-option>
              </el-select>
            </div>
            <div class="btn" @click="submitXX">
              确认信息
            </div>
          </div>
        </div>
        <div class="tc" v-if="!isbuydown" style="width: 690px;">
          <div class="tcGuanbi">
            <img src="@/assets/imgs/shanchuguanbicha(2)@2x.png" alt="" @click="isFreeReceive = false">
          </div>
          <img src="@/assets/imgs/组 2@2x.png" alt="">
          <div class="text1">购买成功</div>
          <div class="tcText tcText2" style="font-size: 15px;">
            恭喜您成为自主填报VIP，有任何问题请咨询客服’
          </div>
          <div class="btn" style="left: 16%;width: 48%;top: 80%;" @click="seeererefsdf">
            查看权益
          </div>
        </div>
      </div>
    </el-dialog> -->
  </div>

</template>

<script>
import { REGInformationList, getInforList } from "@/api/collegeinformation.js";
import { bannerList, getzzvip, remssdfsdfsdf } from "@/api/homeIndex.js"
import { majorhotList, hotconsultsList, queryCollegeBcuRank } from '@/api/resourceAndconsult'
import hotSchool from "@/components/hotSchool.vue"
import resourceAndExamination from "@/components/resourceAndExamination.vue"
import { getUserInfo, } from '@/api/user'
import cardVip from '@/components/cardVip.vue'

import Slider from '@/components/Slider'
import { mapState } from 'vuex'

export default {
  name: "Home",
  components: {
    hotSchool,
    resourceAndExamination,
    cardVip,
    Slider,

  },
  props: {},
  computed: {
    ...mapState({
      userType: state => state.userInfo.userType,
      trans: state => state.userInfo.trans,
    })
  },
  data() {
    return {
      value: [406, 496],
      inputDis: false,      //是否可以修改分数 ，false：可以修改，true：禁止修改
      subjectDis: false,    //是否可以修改科目 ，false：可以修改，true：禁止修改
      optionSubjectDis: false,   //是否可以修改次选科目 ，false：可以修改，true：禁止修改
      isFillTimeFlag: false, //是否是报考期间，false为填报时间外，true为填报期间
      // 资讯列表
      ziList: [],
      dialogVisible: false,
      dilogTitle: '',
      tableData: [],
      tableDataList: [],
      fensInput: '',
      fruits: ['物理', '历史'],
      selectedFruits: [],
      fruits2: ['生物', '地理', '化学', '政治'],
      selectedFruits2: [],
      fruits3: ['本科批', '专科批'],
      selectedFruits3: '本科批',
      introductionList: [],
      input: '',
      fatr: false,
      list: [
        {
          img: require("@/assets/imgs/school.png"),
          title: "查学校",
          routerpath: '/checkschools',
        },
        {
          img: require("@/assets/imgs/profession.png"),
          title: "查专业",
          routerpath: '/professionalinvestigation',
        },
        {
          img: require("@/assets/imgs/weici.png"),
          title: "查位次",
          routerpath: '/Onebyone'
        },
        {
          img: require("@/assets/imgs/career.png"),
          title: "查职业",
          routerpath: '/occupation'
        },
        {
          img: require("@/assets/imgs/tiqianpi.png"),
          title: "提前批",
          routerpath: '/advance'
        },
        {
          img: require("@/assets/imgs/schoolplan.png"),
          title: "招生计划",
          routerpath: '/students',
        },
        {
          img: require("@/assets/imgs/xuekepinggu.png"),
          title: "学科评估",
          routerpath: '/curriculumJudgement'
        },
        {
          img: require("@/assets/imgs/batchline.png"),
          title: "批次线",
          routerpath: '/Batchline'
        },
      ],
      hotDivList: [
        { iconPath: require("@/assets/imgs/hotschool.png"), text: '热门学院排名', id: 1 },
        { iconPath: require("@/assets/imgs/hotzhuanye.png"), text: '热门专业', id: 2 },
        { iconPath: require("@/assets/imgs/hotzixun.png"), text: '热门咨询', id: 3 },
        { iconPath: require("@/assets/imgs/hotzhidao.png"), text: '操作指导', id: 4 },
      ],
      isFreeReceive: false,
      isFreeReceive_: true,
      isbuydown: true,
      expiryTime: ''
    };
  },

  watch: {

  },
  created() {


  },
  mounted() {

    // 获取首页轮播图
    this.getBannerList()
    // 获取高考资料（暂时弃用）+资讯
    this.getGk();

  },
  activated() {
    this.getInfoFs()
    // 将批次存储到浏览器缓存
    const batchfileSync = localStorage.getItem('batchFile');
    if (batchfileSync) {
      this.selectedFruits3 = batchfileSync
    } else {
      localStorage.setItem('batchFile', this.selectedFruits3)
    }



  },
  methods: {

    handLub(index) {
      // const {userType} = this.$store.state.userInfo;
      // if (!localStorage.getItem('phonenumber') && index == 3) {
      //   const channel = new BroadcastChannel('share')
      //   channel.postMessage(true)
      // } else {
      //   if (index == 3) {
      //     if(userType == '01'){
      //         this.isFreeReceive = true
      //       this.isFreeReceive_ = false
      //     }else{
      //       this.$message.success('您已是VIP用户，无需领取')
      //     }

      //   }
      // }
    },

    // 查看权益，跳转至vip页面
    seeererefsdf() {
      this.isFreeReceive = false;
      this.$router.push({ path: '/vip' })
    },
    //  点击立即领取图片
    receiveed() {
      if (!localStorage.getItem('phonenumber')) {
        this.isFreeReceive = false
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
      } else {
        this.isFreeReceive_ = false
      }
    },
    //  点击确认消息
    submitXX() {
      const phoneNumber = localStorage.getItem('phonenumber');
      if (!this.expiryTime) {
        return this.$message.warning('请选择高考年份')
      }
      getzzvip({ phoneNumber, expiryTime: this.expiryTime + '-09-30 23:59:59' }).then(async res => {
        // 开之后，调取个人信息
        await this.$store.dispatch('setUserinfo')
        this.isbuydown = false
      })
    },
    // 获取个人用户信息
    async getInfoFs() {
      const phonenumber = localStorage.getItem('phonenumber')
      if (phonenumber) {
        await this.$store.dispatch('setUserinfo') // 重新获取个人信息
        const userInfo = this.$store.state.userInfo;
        this.fensInput = userInfo.score || ''
        this.selectedFruits = userInfo.subject
        this.selectedFruits2 = userInfo.optionalSubject ? JSON.parse(userInfo.optionalSubject) : [];
        let promiChange = await this.isChangeScoreOrSubject(userInfo.score, userInfo.isUpdateScore, userInfo.subject, userInfo.trans)
        this.inputDis = promiChange.inputDis
        this.subjectDis = promiChange.subjectDis
        this.optionSubjectDis = promiChange.optionSubjectDis
        this.isFillTimeFlag = promiChange.isFillTimeFlag
        this.isFreeReceive = userInfo.userType == '01'
      } else {
        this.isFreeReceive = true;
      }
    },
    batchChange() {
      localStorage.setItem('batchFile', this.selectedFruits3)
    },
    zxClick(item) {
      this.$router.push({ path: '/zxxq', query: { infoId: item.infoId } });
    },
    tb(val) {
      let tophone = localStorage.getItem("phonenumber")
      if (tophone) {
        if (!this.fensInput) {
          this.$message.warning('请完善分数')
          return
        }
        if (this.fensInput > 750 || this.fensInput <= 0 || isNaN(this.fensInput)) {
          return this.$message.warning('请输入正确的分数')
        }
        let tf = this.selectedFruits && this.selectedFruits2.length == 2
        if (tf) {
          // 能改分，并且是填报期间才给提示
          if (this.$store.state.userInfo.isUpdateScore == 1 && this.isFillTimeFlag) {
            this.$confirm(' 注意！目前只限输入真实高考分数，输入后不可更改，请仔细检查，认真核对！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.govoluntPage(val)
            }).catch(() => {

            });

          } else {
            this.govoluntPage(val)
          }

        } else {
          this.$message.warning('请选择首选科目或选择两个再选科目')
          return
        }
      } else {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        // this.$message.warning("请登录后填报")
      }



    },

    govoluntPage(val) {
      if (val == 1) {
        this.$router.push({
          path: '/fillingZZ', query: {
            fensInput: this.fensInput,
            selectedFruits: this.selectedFruits,
            selectedFruits2: this.selectedFruits2,
            selectedFruits3: this.selectedFruits3
          }
        })
      } else if (val == 2) {
        this.$router.push({
          path: '/fillingZN', query: {
            fensInput: this.fensInput,
            selectedFruits: this.selectedFruits,
            selectedFruits2: this.selectedFruits2,
            selectedFruits3: this.selectedFruits3
          }
        })
      }
    },

    handleClose() {

      this.isdialogVisible()

    },
    handleRowClick(row) {

      // 院校
      if (row.collegeNumber) {
        this.$router.push({
          path: '/profile',
          query: {
            collegeNumber: row.collegeNumber,
            activeId: 0
          }
        })
      }
      // 专业
      if (row.professionalCode) {
        this.$router.push({
          path: '/goprofessionDetail',
          query: {
            professionalCode: row.professionalCode,
            scrollTop: 0
          }
        })
      }
      // 咨询
      if (row.consultsId) {
        this.$router.push({
          path: '/serviceItem',
          query: {
            consultsId: row.consultsId,
          }
        })
      }
      this.dialogVisible = false
    },
    ahandclick(item) {
      // 热门院校
      if (item.id == '1') {
        this.dilogTitle = '热门院校排行'
        this.isdialogVisible()
        this.getHotSchoolList()
      }
      // 热门专业
      if (item.id == '2') {
        this.dilogTitle = '热门专业排行'
        this.isdialogVisible()
        this.getmajorhotList()
      }
      // 热门咨询
      if (item.id == "3") {
        this.dilogTitle = '热门咨询排行'
        this.isdialogVisible()
        this.getHotconsultsList()
      }
      // 操作指导
      if (item.id == "4") {
        this.$router.push({ path: '/examinationdata' })
      }
    },
    // 弹窗事件
    isdialogVisible() {
      this.dialogVisible = !this.dialogVisible
    },
    // 热门院校
    getHotSchoolList() {
      this.tableData = []
      this.tableDataList = [
        { prop: 'collegeName', label: '专业名称' },
        { prop: 'collegeType', label: '院校层次' },
        { prop: 'bcuRanking', label: '排名' },
      ]
      queryCollegeBcuRank({ collegeType: "本科" }).then(res => {
        this.tableData = res.rows.map(item => {
          return {
            ...item,
            schoolName: item.collegeName,
            schoollevel: item.collegeType
          }
        })

      })
    },
    // 热门专业
    getmajorhotList() {
      this.tableData = []
      this.tableDataList = [
        { prop: 'professionalName', label: '专业名称' },
        { prop: 'professionalCode', label: '专业编号' },
        { prop: 'orderNum', label: '排名' },
      ]
      majorhotList().then(res => {
        if (res.code == 200) {
          this.tableData = res.rows
        }
      })
    },

    // 热门咨询
    getHotconsultsList() {
      this.tableData = []
      this.tableDataList = [
        // { prop: 'consultsId', label: '咨询id' },
        { prop: 'consultsTitle', label: '咨询标题' },
        { prop: 'consultsIntro', label: '咨询介绍' },
        { prop: 'consultsNum', label: '排名' },
      ]
      hotconsultsList().then(res => {
        if (res.code == 200) {
          this.tableData = res.rows
        }
      })
    },

    godetail(item) {

      let phonenumber = localStorage.getItem('phonenumber')
      if (item.routerpath == '/advance' || item.routerpath == '/students' || item.routerpath == "/Onebyone") {     //提前批 或者招生计划
        if (!phonenumber) {
          const channel = new BroadcastChannel('share')
          channel.postMessage(true)
          // this.$message.warning("请先登录")
          return
        } else {
          if (this.$store.state.userInfo.userType == '01') {
            this.$refs.cardVip.hand()
            return
          }
        }

      }
      this.$router.push({
        path: item.routerpath
      })
    },
    // 获取高考资料and 资讯
    getGk() {
      const self = this;

      // REGInformationList(this.queryParams).then((res) => {
      //   if (res.code == 200) {
      //     res.rows.forEach((item) => {
      //       item.detailedIntroductionText = item.detailedIntroduction ? item.detailedIntroduction.replace(/<.*?>/g, "") : ''
      //     });
      //     self.listGK = res.rows;


      //   }
      // });

      getInforList().then(res => {
        res.data.forEach(item => {
          this.ziList.push(
            {
              ...item,
              imageUrl: process.env.VUE_APP_BASE_API + item.imageUrl
            }
          )

        })
        this.ziList.sort((a, b) => {
          if (a.type == 1 && !b.type == 1) {
            return -1;
          } else if (!a.type == 2 && b.type == 2) {
            return 1;
          } else {
            return 0;
          }
        });
      })
    },
    // 高考资料每一项点击
    handClick(item) {
      this.tabActive = item.dictValue
      this.queryParams.dataType = this.tabActive;
      this.queryParams.pageNum = 1;
      this.getGk();
    },
    getBannerList() {
      bannerList({ imageType: 1 }).then(res => {
        this.introductionList = res.data.map(item => {
          return {
            ...item,
            navCoverUrl: process.env.VUE_APP_BASE_API + item.navCoverUrl
          }
        })

      })
    },


  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/zixun.scss";
@import "@/assets/css/reousrces.scss";

.index {
  user-select: none;
  box-sizing: border-box;
  width: 100%;
  // height: 805px;
  padding: 0px 0 0px 0;

  .routerview {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    .routerviewItem {
      text-align: center;
      cursor: pointer;

      img {
        width: 70px;
      }

      img:hover {
        transform: translateY(-10px);
        transition: 0.3s;
      }

      span {
        display: block;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-top: 7px;
      }
    }

    .routerviewItem:hover span {
      color: #12ae96;
    }
  }

  .left_information {
    display: flex;
    height: 75px;

    .ceyicetitle {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 26px;
      position: relative;
    }

    .ceyicetitle::before {
      content: "";
      width: 60px;
      height: 8px;
      background: #74d19d;
      position: absolute;
      bottom: 10px;
      left: 0;
      z-index: 2;
    }
  }

  .tabArr {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;

    .tabArrItem {
      display: inline-block;
      color: #bdbdbd;
      font-size: 18px;
      margin: 0 15px;
    }

    .activeTab {
      color: #12ae96;
      font-weight: bold;
    }
  }

  .more {
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: auto;
    line-height: 80px;
  }
}

.lub {
  position: relative;

  .fens {
    position: absolute;
    left: 50%;
    margin-left: 275px;
    top: 5.6%;
    width: 330px;
    // width: 24%;
    height: 320px;
    background: #fff;
    z-index: 10;
    border-radius: 10px;
    box-shadow: 0 0 3px #a6a6a6;
    padding: 0 20px;
    box-sizing: border-box;

    .textfens {
      position: relative;
      width: 235px;
      margin: auto;
    }

    .textfens input {
      width: 100%;
      height: 50px;
      border-bottom: 3px solid #12ae96;
      font-size: 50px;
      font-weight: 500;
      text-align: center;
      line-height: 50px;
      margin: 25px auto 0;
      display: block;
    }

    .textfens input::-webkit-input-placeholder {
      /* WebKit browsers */
      font-size: 16px;
      color: #ccc;
    }

    .textfens input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      font-size: 16px;
      color: #ccc;
    }

    .textfens input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      font-size: 16px;
      color: #ccc;
    }

    .textfens input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      font-size: 16px;
      color: #ccc;
    }

    .pen {
      width: 45px;
      position: absolute;
      top: 37%;
      left: 84%;
    }

    .chk {
      margin-top: 25px;

      span {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }

      label {
        height: 10px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-left: 2%;
        margin-top: 1%;

        input[type="checkbox"],
        input[type="radio"] {
          appearance: none;
          /*取消默认的复选框样式*/
          /*重新写一个复选框圆角样式*/
          width: 15px;
          /*设置方形宽度为20px*/
          height: 15px;
          /*设置方形高度为20px*/
          border: 1px solid #aaaaaa;
          /*设置边框样式*/
          border-radius: 50%;
          /*将方形的边框改为圆形*/
        }

        /*复选框选择后的样式*/
        input[type="checkbox"]:checked,
        input[type="radio"]:checked {
          background: #12ae96;
          border-color: #12ae96;
        }
      }
    }
  }
}

.btns {
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  margin: 20px 0;
  cursor: pointer;

  .btn {
    width: 42%;
    height: 38px;
    background-image: url("../assets/imgs/btn1.png");
    background-size: 100% 100%;
    line-height: 38px;
    text-align: center;
    color: #fff;
    font-size: 19px;
    font-weight: 900;
  }

  .btn2 {
    background-image: url("../assets/imgs/btn2.png");
  }
}

:deep(.freeDialog) {
  background-color: transparent;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0);
  //  margin: 0vh 0 0px 480px!important;
  margin-top: -1vh !important;
}

.freeBox {
  position: relative;
  width: 500px;
  height: 520px;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: infinite;

  .freePic {
    position: absolute;
    width: 100%;
    z-index: 5;
  }

  .freeBtn {
    position: absolute;
    width: 50%;
    bottom: 70px;
    left: 125px;
    z-index: 9;
    cursor: pointer;
  }

  .closeIcon {
    font-size: 40px;
    color: white;
    position: absolute;
    right: 40px;
    top: 30px;
    z-index: 9;
    cursor: pointer;
  }
}

.tc2Text2 {
}

.tc2Text3 {
  width: 100%;

  margin: 15px 0;
}

.tc2 .btn {
}

@-webkit-keyframes bounceInRight {
  0% {
    -webkit-transform: translate(0px, 0px);
  }

  50% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes bounceInRight {
  0% {
    -webkit-transform: translate(0px, 0px);
  }

  60% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
</style>
